import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import React, { useState, useEffect } from "react";
import MasterServices from "../../ApiServices/MasterServices";
import { toast } from "react-toast";

function ReceptionSchedule({ open, onClose, reservationID }) {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [availabilityData, setAvailabilityData] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState([]);

  const departmentOptions = [{ value: 1, label: "Nursing" }];

  useEffect(() => {
    if (selectedDate && selectedDepartments.length > 0) {
      fetchAvailabilityData(selectedDate, selectedDepartments);
    } else {
      setAvailabilityData([]);
    }
  }, [selectedDate, selectedDepartments]);

  const fetchAvailabilityData = (date, departments) => {
    uncheckAll();
    MasterServices.getAvailabilityForDepartment(
      date,
      departments.map((v) => v.value)
    )
      .then((res) => res.data)
      .then((res) => {
        const { data, status } = res;
        if (status === 200) {
          setAvailabilityData(data);
        } else {
          // TODO: add error mgmt
        }
      })
      .catch((err) => {})
      .finally(() => {});
  };

  const openNurseDetails = (user, slot) => {
    setSelectedUser({ ...user, slot }); // Set the selected user details with slot
    setDetailsModalOpen(true);
  };

  const closeNurseDetails = () => {
    setSelectedUser(null);
    setDetailsModalOpen(false);
  };

  const createAppointment = () => {
    let date = new Date(selectedDate);
    let slot = new Date(selectedSlot);
    date.setHours(slot.getHours());
    date.setMinutes(slot.getMinutes());
    date.setSeconds(slot.getSeconds());
    date.setMilliseconds(slot.getMilliseconds());
    MasterServices.createAppointment(
      date.toISOString(),
      selectedUsers,
      parseInt(reservationID)
    )
      .then((res) => res.data)
      .then((res) => {
        toast.success("Appointment created successfully.");
        window.location.reload(true);
      })
      .catch((err) => {
        toast.error("Error occured. Please retry");
      })
      .finally(() => {});
  };

  const uncheckAll = () => {
    setSelectedDepartment([]);
    setSelectedUsers([]);
    setSelectedSlot(null);
  };

  useEffect(() => {
    if (selectedDate && selectedDepartments.length > 0) {
      fetchAvailabilityData(selectedDate, selectedDepartments);
    } else {
      setAvailabilityData({});
    }
  }, [selectedDate, selectedDepartments]);

  const onClickUser = (slot, user_id, department_id, checked = true) => {
    // console.log({ slot, user_id, department_id, checked });
    if (checked) {
      /**
       * if slot isn't already set, set slot
       * push to selected user state
       * push to selected department state
       */
      if (!selectedSlot) setSelectedSlot(slot);
      setSelectedUsers((prev) => [...prev, user_id]);
      setSelectedDepartment((prev) => [...prev, department_id]);
    } else {
      /**
       * pop from selected user state
       * pop from selected department state
       * if (department state length is 0), set slot as null
       */
      let newUserList = Array.from(selectedUsers);
      newUserList = newUserList.filter((a) => a != user_id);
      setSelectedUsers(newUserList);
      let newDeptList = Array.from(selectedDepartment);
      newDeptList = newDeptList.filter((a) => a != department_id);
      setSelectedDepartment(newDeptList);
      if (newDeptList.length === 0) setSelectedSlot(null);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        uncheckAll();
        onClose();
      }}
      className="relative z-30"
    >
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-5xl data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 min-h-[500px]">
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:mt-0 sm:text-left flex gap-5 mx-auto">
                  <div className="mt-4">
                    <label className="block text-sm font-medium text-gray-700">
                      Select Date
                    </label>
                    <DatePicker
                      selected={selectedDate}
                      onChange={(date) => {
                        let timezone = new Date().getTimezoneOffset();
                        date.setMinutes(date.getMinutes() - timezone);
                        setSelectedDate(date);
                      }}
                      className="mt-2 w-full rounded-md border-gray-300 shadow-sm"
                      placeholderText="Pick a Date"
                    />
                  </div>
                  <div className="mt-4">
                    <label className="block text-sm font-medium text-gray-700">
                      Select Departments
                    </label>
                    <Select
                      isMulti
                      options={departmentOptions}
                      value={selectedDepartments}
                      onChange={(options) => setSelectedDepartments(options)}
                      className="mt-2"
                      placeholder="Select departments"
                    />
                  </div>
                </div>
              </div>

              {Object.keys(availabilityData).length > 0 && (
                <div className="mt-6">
                  <div className="grid grid-rows-1 grid-flow-col gap-4">
                    <div>
                      <h3 className="px-4 py-3 text-lg font-semibold text-gray-700">
                        Nurse Availability
                      </h3>
                    </div>
                    <div className="px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                      <button
                        type="button"
                        onClick={() => {
                          uncheckAll();
                          onClose();
                        }}
                        className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                      >
                        Close
                      </button>
                      <button
                        className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                        onClick={uncheckAll}
                      >
                        Clear Selection
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          if (selectedUsers.length > 0) {
                            const user = availabilityData[selectedSlot]?.find(
                              (u) => u.user_id === selectedUsers[0]
                            );
                            if (user) openNurseDetails(user, selectedSlot);
                          }
                        }}
                        className="mr-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                      >
                        Schedule
                      </button>
                    </div>
                  </div>

                  <table className="min-w-full mt-2 border rounded-lg">
                    <thead>
                      <tr>
                        <th className="px-4 py-2 text-left bg-gray-100">
                          Time Slot
                        </th>
                        <th className="px-4 py-2 text-left bg-gray-100">
                          Department / Team
                        </th>
                        <th className="px-4 py-2 text-left bg-gray-100"></th>
                        {/* <th className="px-4 py-2 text-left bg-gray-100">Action</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(availabilityData).map((slot) => (
                        <React.Fragment key={slot}>
                          {availabilityData[slot].map((user, userIndex) => (
                            <tr key={user.user_id} className="border-t">
                              {userIndex === 0 && (
                                <td
                                  className="px-4 py-2"
                                  rowSpan={availabilityData[slot].length}
                                >
                                  {new Date(slot).toLocaleTimeString()}
                                </td>
                              )}
                              <td className="px-4 py-2">
                                {user.deparment_name} - {user.user_name}
                              </td>
                              <td className="px-4 py-2">
                                <input
                                  type="checkbox"
                                  onClick={(e) =>
                                    onClickUser(
                                      slot,
                                      user.user_id,
                                      user.department_id,
                                      e.target.checked
                                    )
                                  }
                                  className={`disabled:bg-gray-500
                                    ${
                                      selectedUsers.includes(user.user_id) &&
                                      selectedSlot === slot
                                        ? "bg-lime-400 border border-lime-400 cursor-pointer"
                                        : ""
                                    }
                                    ${
                                      !!selectedSlot && selectedSlot !== slot
                                        ? "bg-gray-500 border border-gray-300 cursor-pointer"
                                        : ""
                                    }
                                    ${
                                      selectedDepartment.includes(
                                        user.department_id
                                      )
                                        ? "bg-cyan-600 border border-cyan-300 cursor-pointer"
                                        : ""
                                    }
                                    `}
                                  checked={
                                    selectedUsers.includes(user.user_id) &&
                                    selectedSlot === slot
                                  }
                                  disabled={
                                    (!!selectedSlot && selectedSlot !== slot) ||
                                    selectedDepartment.includes(
                                      user.department_id
                                    )
                                  }
                                />
                              </td>
                              {/* <td className="px-4 py-2">
                                {user.department_id && (
                                  <button onClick={() => openNurseDetails(user, slot)} className="text-blue-500 underline hover:text-blue-700">
                                    {user.deparment_name} Details
                                  </button>
                                )}
                              </td> */}
                            </tr>
                          ))}
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </DialogPanel>
        </div>
      </div>

      {/* Nurse Details Modal */}
      {detailsModalOpen && selectedUser && (
        <Dialog
          open={detailsModalOpen}
          onClose={closeNurseDetails}
          className="relative z-40"
        >
          <DialogBackdrop className="fixed inset-0 bg-gray-500 bg-opacity-75" />
          <div className="fixed inset-0 flex items-center justify-center">
            <DialogPanel className="w-full max-w-md p-6 bg-white rounded-lg shadow-lg">
              <h2 className="text-xl font-semibold text-gray-700">
                Nurse Details
              </h2>
              <p className="mt-4">
                Reservation ID: {reservationID ? reservationID : ""}
              </p>
              <p className="mt-4">Name: {selectedUser.user_name}</p>
              <p className="mt-4">Department: {selectedUser.deparment_name}</p>
              <p className="mt-4">
                Selected Slot:{" "}
                {new Date(selectedUser.slot).toLocaleTimeString()}
              </p>
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:px-6 justify-between my-5">
                <button
                  onClick={createAppointment}
                  className="inline-flex float-left w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
                >
                  Save
                </button>
                <button
                  onClick={closeNurseDetails}
                  className="inline-flex float-right w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                >
                  Cancel
                </button>
              </div>
            </DialogPanel>
          </div>
        </Dialog>
      )}
    </Dialog>
  );
}

export default ReceptionSchedule;
