import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ProtectedRoute from "./protectedRoute";
import Home from "../container/pages/dashboard/home";
import Login from "../container/pages/login/Login";
import PackageList from "../container/pages/packages/PackageList";
import Hospital from "../container/pages/hospital/HospitalList";
import HospitalTab from "../container/pages/hospital/HospitalTab";
import AddHospital from "../container/pages/hospital/addHospital";
import PriceList from "../container/pages/price_list/PriceList";
import PriceListTab from "../container/pages/price_list/PriceListTab";
import Location from "../container/pages/location/LocationList";
import LocationTestList from "../container/pages/location/LocationTestList";
import AddPurchaseOder from "../container/pages/purchase_order/AddPurchaseOrders";
// import Users from "../container/pages/oldPages/user/UserList";
import UsersList from "../container/pages/users/Users";
import RoleList from "../container/pages/role/RoleListNew";
import AddUser from "../container/pages/users/AddUser";
import CountryList from "../container/pages/country/CountryList";

import { AuthProvider } from "../contexts/Auth";
import GenderList from "../container/pages/gender/Gender";
import StateList from "../container/pages/state/State";
import ClinicRefferal from "../container/pages/clinicRefferal/ClinicRefferal";
import ClinicRefferalLogs from "../container/pages/clinicRefferal/ClinicRefferalLogs";
import AddClinicRefferal from "../container/pages/clinicRefferal/AddClinicRefferal";
import WarehouseItemCategory from "../container/pages/warehouseItemCategory/WarehouseItemCategory";
import AllLogs from "../container/pages/allLogs/AllLogs";
import UrgentLogs from "../container/pages/urgentReport/UrgentLogs";
import AddWarehouseItemCategory from "../container/pages/warehouseItemCategory/AddWarehouseItemCategory";
import Methodology from "../container/pages/methodology/Methodology";
import AddMethodology from "../container/pages/methodology/AddMethodology";
import CityList from "../container/pages/city/CityNew";
import LabAnalyzer from "../container/pages/lab_analyzer/LabAnalyzer";
import EditPermissions from "../container/pages/role/EditPermissions";

import Category from "../container/pages/category/CategoryList";
import Test from "../container/pages/tests/TestList";
import AddTest from "../container/pages/tests/addTest";
import SubTestList from "../container/pages/subtest/SubTestList";
import AddSubTest from "../container/pages/subtest/addSubTest";
import TestTab from "../container/pages/tests/TestTab";
import AddPackage from "../container/pages/packages/addPackage";
import Reservation from "../container/pages/reservation/Reservation";
import ReservationList from "../container/pages/reservation/ReservationList";
import RefundedList from "../container/pages/reservation/RefundedReservationList";
import InvoicesList from "../container/pages/reservation/InvoicesList";
import RejectedList from "../container/pages/reservation/RejectedReservationList";
import ReservationTranxList from "../container/pages/reservation/ReservationTranxList";
import SampleList from "../container/pages/sample/SampleList";
import BookingConfirmation from "../container/pages/reservation/BookingConfirmation";
import Error from "../container/error/Error";
import BarcodeScanner from "../container/pages/barcodeScanner/BarcodeScanner";
import OutsourcereservationList from "../container/pages/reservation/OutSourceReservationList";
import OutSourceClinicPatientsList from "../container/pages/reservation/OutSourceClinicPatientsList";
import TestList from "../container/pages/tests/TestList";
import ProgrammableComments from "../container/pages/programable_comments/ProgramableComments";
import ControlList from "../container/pages/QC/Control_list/ControlList";
import QCTest from "../container/pages/QC/Control_list/QCTest";
import ItemsList from "../container/pages/Items/ItemsList";
import AddItems from "../container/pages/Items/AddItems";
import Units from "../container/pages/units";
import MUnits from "../container/pages/m_units";
import VendorsList from "../container/pages/Vendros/VendorsList";
import AddVendors from "../container/pages/Vendros/AddVendors";
import VendorDetail from "../container/pages/Vendros/VendorDetail";
import SuperMasters from "../container/pages/super_masters/SuperMasters";
import SampleDetails from "../container/pages/sample/SampleDetails";
import SampleProcessing from "../container/pages/sample_processing/SampleProcessingDepricated";
import SampleProcessingNew from "../container/pages/sample_processing/SamplePrcessingNew";
import ReferenceRangeList from "../container/pages/reference_range/ReferenceRangeList";
import AddReferenceRangeList from "../container/pages/reference_range/addReferenceRange";
import ReferenceRangeTab from "../container/pages/reference_range/ReferenceRangeTab";
import Profile from "../container/pages/profile/Profile";
import Vat from "../container/pages/vat/Vat";
import AddVat from "../container/pages/vat/AddVat";
import GlobalSetting from "../container/pages/globelSetting/GlobelSetting";
import Coupon from "../container/pages/coupon/Coupon";
import CouponCodeList from "../container/pages/coupon/CouponCodeList";
import ReceiveSamples from "../container/pages/receive_samples/ReceiveSamples";
import GlobalSettingList from "../container/pages/global_settings/GlobalSettingList";
import OutSourcedClinic from "../container/pages/outSourcedClinic/OutSourcedClinic";
import ContractPricing from "../container/pages/contractPricing/ContractPricing";
import AddOutsourcedClinic from "../container/pages/outSourcedClinic/AddOutsourcedClinic";
import QualityControl from "../container/pages/qualityControl/QualityControl";

import PurchaseOrder from "../container/pages/purchase_order";
import StorageLocation from "../container/pages/StorageLocation";
import StorageLocationItem from "../container/pages/storageLocationItem";
import Movement from "../container/pages/movement";
import GoodsReceived from "../container/pages/GRNGoodReceived";
import SingleGoodReceived from "../container/pages/GRNGoodReceived/GrnGoodReceived";
import GoodsReturn from "../container/pages/GRNGoodsReturn";
import Invoice from "../container/pages/outSourcedClinic/Invoice";
import InvoiceByMonth from "../container/pages/outSourcedClinic/InvoiceMonthWise";
import Questionnaire from "../container/pages/questionnaire/Questionnaire";
import QuestionnaireList from "../container/pages/questionnaire/QuestionnaireList";
import TestColor from "../container/pages/testcolor/TestColor";
import AddTestColor from "../container/pages/testcolor/AddTestColor";
// import Notification from "../container/pages/notifications/Notification";
import NotificationList from "../container/pages/notifications/NotificationList";
import IncentiveMaster from "../container/pages/incentiveMaster/IncentiveMaster";
import AddIncentiveMaster from "../container/pages/incentiveMaster/AddIncentiveMaster";
import QuestionnaireRender from "../container/pages/questionnaire/QuestionnaireRender";
import AddCoupon from "../container/pages/coupon/AddCoupon";
import NotificationTemplate from "../container/pages/notifications/NotificationTemplate";
import QuestionnairePreview from "../container/pages/questionnaire/QuestionnairePreview";
import IncentiveDashboard from "../container/pages/incentiveDashboard/IncentiveDashboard";
import IncentiveDetails from "../container/pages/incentiveDashboard/IncentiveDetails";

import Region from "../container/pages/region/Region";
import RegionLocationList from "../container/pages/region/RegionLocationList";
import SampleType from "../container/pages/sampleType/SampleType";
import ResQuestionnairePrev from "../container/pages/reservation/ResQuestionnairePrev";
import OutSourcingReport from "../container/pages/outSourcedClinic/OutSourcingReport";
import UserUpdateReport from "../container/pages/userUpdateReport/UserUpdateReport";
import CouponReport from "../container/pages/coupon/CouponReport";
import ReportPDFGenerated from "../container/pages/reports/reportGenerated";
import ReservationPatientLogs from "../container/pages/reports/reservationPatientLogs";
import IncentiveReport from "../container/pages/incentiveDashboard/IncentiveReport";
import DiscountOTPReceivers from "../container/pages/DiscountOTPReceivers/DiscountOTPReceivers";
import AccumulatedRevenueReport from "../container/pages/reports/accumulatedRevenueReport/AccumulatedRevenueReport";
import PatientList from "../container/pages/patient/PatientList";
import PatientMemberList from "../container/pages/patient/PatientMemberList";
import SubTestTabDetails from "../container/pages/subtest/SubTestTab";
import MonthlyRevenueReport from "../container/pages/reports/monthlyRevenueReport/MonthlyRevenueReport";
import OCCreditSpendDetails from "../container/pages/outSourcedClinic/CreditSpendLogs";
import DailyLocationCashRevenue from "../container/pages/reports/dailyLocationCashRevenue/DailyLocationCashRevenue";
import Organisms from "../container/pages/organism/Organism";
import Antibiotics from "../container/pages/anitibiotic/Antibiotic";
import AntibioticResultTypes from "../container/pages/AntibioticResultType/AntibioticResultType";
import SampleProcessingTab from "../container/pages/sample_processing/SampleProcessingTab";
import ReferralTestList from "../container/pages/outSourcedClinic/ReferralTestList";
import CRMSampleProcessing from "../container/pages/crm/CRMSampleProcessing";
// outsource Clinic Report Section Start
import InsuOTReservationReport from "../container/pages/reports/insurance-report/InsuOTReservationReport";
import InsuOTPaitentReport from "../container/pages/reports/insurance-report/InsuOTPaitentReport";
import InsuOTTestReport from "../container/pages/reports/insurance-report/InsuOTTestReport";
// outsource Clinic Report Section End
import User from "../pages/user/Users";
import ShiftMasterList from "../container/pages/shiftMaster/ShiftMasterList";
import UserShift from "../container/pages/shiftMaster/UserShift";
import DepartmentMasterList from "../container/pages/departmentMaster/DepartmentMasterList";
import UserDepartment from "../container/pages/departmentMaster/UserDepartment";

const RoutesConfig = () => {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/signUp" element={<Login />} />
        <Route path="*" element={<Error />} />
        <Route element={<ProtectedRoute />}>
          <Route path="/testUI" element={<User />} />
          <Route path="/" element={<Home />} />
          <Route path="/package" element={<PackageList />} />
          <Route path="/lab" element={<Hospital />} />
          <Route path="/price-list" element={<PriceList />} />
          <Route path="/location" element={<Location />} />
          <Route path="/settings" element={<GlobalSetting />} />
          <Route path="/region" element={<Region />} />
          <Route path="/region-loc/">
            <Route index element={<RegionLocationList />} />
            <Route path=":rgn_id" element={<RegionLocationList />} />
          </Route>
          <Route path="/lab-test/">
            <Route index element={<LocationTestList />} />
            <Route path=":loc_id" element={<LocationTestList />} />
          </Route>
          <Route path="/category" element={<Category />} />
          <Route
            path="/notification-template"
            element={<NotificationTemplate />}
          />
          <Route
            path="/questionnaire-preview"
            element={<QuestionnairePreview />}
          />
          <Route path="/res-questionnaire/">
            <Route index element={<ResQuestionnairePrev />} />
            <Route path=":reservation_id" element={<ResQuestionnairePrev />} />
          </Route>
          <Route path="/test" element={<TestList />} />
          <Route path="/subtest" element={<SubTestList />} />
          <Route path="/barcode-scanner" element={<BarcodeScanner />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/vat" element={<Vat />} />
          <Route path="/outsourced-clinic" element={<OutSourcedClinic />} />
          <Route
            path="/outsourced-clinic-logs"
            element={<OutsourcereservationList />}
          />
          <Route
            path="/outsourced-clinic-patients"
            element={<OutSourceClinicPatientsList />}
          />
          <Route
            path="/outsourced-clinic-credit-spend-details/:id"
            element={<OCCreditSpendDetails />}
          />
          <Route path="/contract-pricing" element={<ContractPricing />} />
          <Route path="/invoice">
            <Route index element={<Invoice />} />
            <Route path=":oc_id" element={<InvoiceByMonth />} />
          </Route>
          <Route path="/incentive-dashboard" element={<IncentiveDashboard />} />
          <Route
            path="/incentive-details/:m_user_id"
            element={<IncentiveDetails />}
          />
          <Route path="/sample-type" element={<SampleType />} />
          <Route path="/outsourcing-report" element={<OutSourcingReport />} />
          <Route path="/user-update-report" element={<UserUpdateReport />} />

          <Route path="/incentive/:role_id" element={<IncentiveMaster />} />
          <Route path="/add-incentive-master/">
            <Route index element={<AddIncentiveMaster />} />
            <Route path=":role_id" element={<AddIncentiveMaster />} />
            <Route path=":role_id/:inct_id" element={<AddIncentiveMaster />} />
          </Route>

          <Route path="/questionnaire/">
            <Route index element={<Questionnaire />} />
            <Route path="render" element={<QuestionnaireRender />} />
            <Route path=":qstn_id" element={<Questionnaire />} />
          </Route>

          {/* <Route path="/users" element={<Users />} /> */}
          <Route path="/user-list" element={<UsersList />} />
          <Route path="/role" element={<RoleList />} />
          <Route path="/role">
            <Route index element={<RoleList />} />
            <Route path=":role_id" element={<EditPermissions />} />
          </Route>
          <Route path="/add-user/">
            <Route index element={<AddUser />} />
            <Route path=":user_id" element={<AddUser />} />
          </Route>
          <Route path="/gender" element={<GenderList />} />
          <Route path="/country" element={<CountryList />} />
          <Route path="/state" element={<StateList />} />
          <Route path="/clinic-refferal/">
            <Route index element={<ClinicRefferal />} />
            <Route path=":resv_ref_id" element={<ClinicRefferalLogs />} />
          </Route>
          <Route path="/add-clinic-refferal/">
            <Route index element={<AddClinicRefferal />} />
            <Route path=":resv_ref_id" element={<AddClinicRefferal />} />
          </Route>

          <Route path="/warehouse-item-category/">
            <Route index element={<WarehouseItemCategory />} />
            <Route path=":wic_id" element={<WarehouseItemCategory />} />
          </Route>
          <Route path="/add-warehouse-item-category/">
            <Route index element={<AddWarehouseItemCategory />} />
            <Route path=":wic_id" element={<AddWarehouseItemCategory />} />
          </Route>

          <Route path="/methodologies/">
            <Route index element={<Methodology />} />
            <Route path=":methodology_id" element={<Methodology />} />
          </Route>
          <Route path="/add-methodology/">
            <Route index element={<AddMethodology />} />
            <Route path=":methodology_id" element={<AddMethodology />} />
          </Route>

          <Route path="/city" element={<CityList />} />
          <Route path="lab-analyzer" element={<LabAnalyzer />} />
          <Route path="/prog-comments" element={<ProgrammableComments />} />
          <Route path="/super-master" element={<SuperMasters />} />
          <Route path="/quality-control" element={<QualityControl />} />
          <Route path="/questionnaire-list" element={<QuestionnaireList />} />
          <Route path="/notification" element={<NotificationList />} />

          {/* <Route path="/users" element={<Users />} /> */}
          <Route path="/user-list" element={<UsersList />} />
          <Route path="/patient-list" element={<PatientList />} />
          <Route
            path="all-member-list/:user_id"
            element={<PatientMemberList />}
          />
          <Route path="/role" element={<RoleList />} />
          <Route path="/role">
            <Route index element={<RoleList />} />
            <Route path=":role_id" element={<EditPermissions />} />
          </Route>
          <Route path="/add-user/">
            <Route index element={<AddUser />} />
            <Route path=":user_id" element={<AddUser />} />
          </Route>
          <Route path="/gender" element={<GenderList />} />
          <Route path="/country" element={<CountryList />} />
          <Route path="/state" element={<StateList />} />
          <Route path="/clinic-refferal/">
            <Route index element={<ClinicRefferal />} />
            <Route path=":resv_ref_id" element={<ClinicRefferalLogs />} />
          </Route>
          <Route path="/add-clinic-refferal/">
            <Route index element={<AddClinicRefferal />} />
            <Route path=":resv_ref_id" element={<AddClinicRefferal />} />
          </Route>
          <Route path="/city" element={<CityList />} />
          <Route path="lab-analyzer" element={<LabAnalyzer />} />
          <Route path="/prog-comments" element={<ProgrammableComments />} />
          <Route path="/super-master" element={<SuperMasters />} />
          <Route path="/quality-control" element={<QualityControl />} />
          <Route path="/shift" element={<ShiftMasterList />} />
          <Route path="/user-shift" element={<UserShift />} />
          <Route path="/department" element={<DepartmentMasterList />} />
          <Route path="/user-department" element={<UserDepartment />} />

          <Route path="/global-setting" element={<GlobalSettingList />} />
          <Route path="/storage-locations" element={<StorageLocation />} />
          <Route path="/stock" element={<StorageLocationItem />} />
          <Route path="/movements" element={<Movement />} />
          <Route path="/goods-received" element={<GoodsReceived />} />
          <Route path="/goods-received/">
            <Route index element={<GoodsReceived />} />
            <Route path=":id" element={<SingleGoodReceived />} />
          </Route>
          <Route path="/goods-return" element={<GoodsReturn />} />
          <Route path="/lab" element={<Hospital />} />
          {/* <Route path="/price-list" element={<PriceList />} /> */}
          <Route path="/location" element={<Location />} />
          <Route path="/category" element={<Category />} />
          {/* <Route path="/test" element={<Test />} /> */}
          <Route path="/reservation" element={<Reservation />} />
          <Route path="/reservationlist" element={<ReservationList />} />
          <Route path="/refundedlist" element={<RefundedList />} />
          <Route path="/rejectedlist" element={<RejectedList />} />
          <Route path="/invoiceslist" element={<InvoicesList />} />
          <Route path="/samplelist" element={<SampleList />} />
          <Route path="/samplelist/:sample_id" element={<SampleDetails />} />
          <Route path="/bookingConfirm" element={<BookingConfirmation />} />
          <Route path="/bookingConfirm/">
            <Route index element={<BookingConfirmation />} />
            <Route path=":reservation_id" element={<BookingConfirmation />} />
          </Route>
          <Route path="/location/">
            <Route index element={<Location />} />
            <Route path=":hos_id" element={<Location />} />
          </Route>
          <Route path="/add-coupon/">
            <Route index element={<AddCoupon />} />
            <Route path=":hos_id" element={<AddCoupon />} />
          </Route>

          <Route path="/price-list/">
            <Route index element={<PriceList />} />
            <Route path=":price_id" element={<PriceListTab />} />
          </Route>
          <Route path="/test-color/">
            <Route index element={<TestColor />} />
            <Route path=":test_color_id" element={<PriceListTab />} />
          </Route>
          <Route path="/add-test-color/">
            <Route index element={<AddTestColor />} />
            <Route path=":test_color_id" element={<AddTestColor />} />
          </Route>
          <Route path="/hospital-tab/">
            <Route index element={<HospitalTab />} />
            <Route path=":hos_id" element={<HospitalTab />} />
          </Route>
          <Route path="/add-hospital/">
            <Route index element={<AddHospital />} />
            <Route path=":hos_id" element={<AddHospital />} />
          </Route>
          <Route path="/add-vat/">
            <Route index element={<AddVat />} />
            <Route path=":vat_id" element={<AddVat />} />
          </Route>
          <Route path="/add-test/">
            <Route index element={<AddTest />} />
            <Route path=":test_id" element={<AddTest />} />
          </Route>
          <Route path="/subtest/">
            <Route index element={<SubTestList />} />
            <Route path=":test_id" element={<SubTestList />} />
          </Route>
          <Route path="/add-subtest/">
            <Route index element={<AddSubTest />} />
            <Route path=":test_id/" element={<AddSubTest />} />
            <Route path=":test_id/:ttp_id" element={<AddSubTest />} />
          </Route>
          <Route path="/test-tab/">
            <Route index element={<TestTab />} />
            <Route path=":test_id" element={<TestTab />} />
          </Route>

          <Route path="/sub-test-tab/">
            <Route path=":test_id" element={<SubTestTabDetails />} />
            <Route path=":test_id/:ttp_id" element={<SubTestTabDetails />} />
          </Route>
          <Route path="/add-outsourced-clinic/">
            <Route index element={<AddOutsourcedClinic />} />
            <Route path=":id" element={<AddOutsourcedClinic />} />
          </Route>
          <Route path="/add-package/">
            <Route index element={<AddPackage />} />
            <Route path=":package_id" element={<AddPackage />} />
          </Route>
          <Route path="/qc/">
            <Route path="control-list" element={<ControlList />} />
            <Route path="test/:id" element={<QCTest />} />
          </Route>
          <Route path="/purchase_order" element={<PurchaseOrder />} />
          <Route path="/add-purchase-order/">
            <Route index element={<AddPurchaseOder />} />
            <Route path=":id" element={<AddPurchaseOder />} />
          </Route>

          <Route path="/items" element={<ItemsList />} />
          <Route path="/units" element={<Units />} />
          <Route path="/m-units" element={<MUnits />} />
          <Route
            path="/accumulated-revenue-report"
            element={<AccumulatedRevenueReport />}
          />
          <Route
            path="/monthly-revenue-report"
            element={<MonthlyRevenueReport />}
          />
          <Route path="/add-items/">
            <Route index element={<AddItems />} />
            <Route path=":item_id" element={<AddItems />} />
          </Route>

          <Route path="/vendors" element={<VendorsList />} />
          <Route path="/add-vendor/">
            <Route index element={<AddVendors />} />
            <Route path=":vendor_id" element={<VendorDetail />} />
          </Route>
          <Route path="/sample-processing" element={<SampleProcessingNew />} />
          <Route
            path="/sample-processing-new"
            element={<SampleProcessingNew />}
          />
          <Route
            path="/sample-processing-tab"
            element={<SampleProcessingTab />}
          />
          <Route path="/coupons" element={<Coupon />} />
          <Route path="/coupons/">
            <Route index element={<Coupon />} />
            <Route path=":id" element={<CouponCodeList />} />
          </Route>
          <Route path="/reference-ranges" element={<ReferenceRangeList />} />
          {/* <Route path="/add-reference-ranges" element={<AddReferenceRangeList />} /> */}
          <Route path="/add-reference-ranges/">
            <Route index element={<AddReferenceRangeList />} />
            <Route path=":mrr_id" element={<ReferenceRangeTab />} />
          </Route>
          <Route path="/receive-samples" element={<ReceiveSamples />} />
          <Route path="/coupon-report" element={<CouponReport />} />
          <Route
            path="/report-pdf-generated"
            element={<ReportPDFGenerated />}
          />
          <Route
            path="/res-patient-report"
            element={<ReservationPatientLogs />}
          />
          <Route path="/incentive-report" element={<IncentiveReport />} />
          <Route path="/reservation-trnx" element={<ReservationTranxList />} />
          <Route path="/user-access-list" element={<DiscountOTPReceivers />} />
          <Route path="/all-logs" element={<AllLogs />} />
          <Route path="/urgent-logs" element={<UrgentLogs />} />
          <Route
            path="/location-revenue"
            element={<DailyLocationCashRevenue />}
          />
          <Route path="/organisms" element={<Organisms />} />
          <Route path="/antibiotics" element={<Antibiotics />} />
          <Route
            path="/antibiotic-result-types"
            element={<AntibioticResultTypes />}
          />
          <Route path="/test-package-list" element={<ReferralTestList />} />
          <Route
            path="/crm-sample-processing"
            element={<CRMSampleProcessing />}
          />

          <Route
            path="/ot-reservation-report"
            element={<InsuOTReservationReport />}
          />
          <Route path="/ot-patient-report" element={<InsuOTPaitentReport />} />
          <Route path="/ot-test-report" element={<InsuOTTestReport />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default RoutesConfig;
