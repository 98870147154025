import { Fragment, useState, useEffect } from "react";
import { Link, NavLink, Navigate, useNavigate } from "react-router-dom";
import { Dialog, Menu, Transition, Combobox } from "@headlessui/react";
import {
  ChevronDownIcon,
  ClockIcon,
  PaintBrushIcon,
  TagIcon,
  UserCircleIcon,
} from "@heroicons/react/20/solid";
import { ToastContainer } from "react-toast";
import { BASEURL } from "../helper/constant";
import { toast } from "react-toast";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import SarCurrencySybol from "../assets/images/sar-currency-sybol.png";
import { FallingLinesLoader } from "../components/spinners/Spinner";

import {
  Bars3BottomLeftIcon,
  BellIcon,
  HomeIcon,
  BuildingOffice2Icon,
  CalendarDaysIcon,
  UsersIcon,
  XMarkIcon,
  BeakerIcon,
  MapPinIcon,
  UserIcon,
  CircleStackIcon,
  ClipboardDocumentCheckIcon,
  WalletIcon,
  AdjustmentsHorizontalIcon,
  CurrencyRupeeIcon,
  GlobeAltIcon,
  BuildingLibraryIcon,
  ViewfinderCircleIcon,
  ChartPieIcon,
  ClipboardDocumentListIcon,
  MagnifyingGlassIcon,
  DocumentChartBarIcon,
} from "@heroicons/react/24/outline";
import { useDispatch } from "react-redux";
import { handleLogout } from "../redux/auth/actions";
import Notification from "../container/pages/notifications/Notification";
import { useSelector } from "react-redux";
import { getLoginUserInfo } from "../redux/userInfo/actions";
import MasterServices from "../ApiServices/MasterServices";
import { getUsersList } from "../redux/patientUserList/actions";
import { setupInactivityTimer } from "../helper/commonHelper";
import { RiyalIcon } from "../components/user/RiyalIcon";
// import RiyalIcon from "../components/user/RiyalIcon";

// const userData = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null;
// console.log("sudhir user data", userData.role_en);
// userData && userData.role_en && typeof userData.role_en === "string" && userData.role_en.toLowerCase() === "lab manager" ? false :

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const DashboardLayout = ({ children }) => {
  const animatedComponents = makeAnimated();
  const permissions = JSON.parse(localStorage.getItem("user_permissions"));
  const [loading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useState(localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null)
  const [locationLoader, setLocationLoader] = useState(false)
  const [res_role_id, setResRoleID] = useState(localStorage.getItem("TBB_role_id")
    ? localStorage.getItem("TBB_role_id")
    : null);


  const Menus = [
    {
      name: "Home",
      href: "/",
      icon: HomeIcon,
      current: true,
      is_permitted:
        permissions?.filter(
          (p) => p.permission_name_en == "Home Admin" && p.permission_id == 132
        )?.length > 0,
    },
    {
      name: "Users",
      href: "/user-list",
      icon: UsersIcon,
      current: true,
      is_permitted:
        permissions?.filter(
          (p) => p.permission_name_en == "User Admin" && p.permission_id == 133
        )?.length > 0,
    },
    {
      name: "Patients",
      href: "/patient-list",
      icon: UsersIcon,
      current: true,
      is_permitted:
        permissions?.filter(
          (p) => p.permission_name_en == "User Admin" && p.permission_id == 133
        )?.length > 0,
    },
    {
      name: "Roles",
      href: "/role",
      icon: UserIcon,
      current: true,
      is_permitted:
        permissions?.filter(
          (p) => p.permission_name_en == "Roles Admin" && p.permission_id == 134
        )?.length > 0,
    },
    {
      name: "Admin",
      submenu: true,
      current: false,
      icon: AdjustmentsHorizontalIcon,
      is_permitted:
        permissions?.filter((p) =>
          [135, 136, 126, 127, 137, 142].includes(p.permission_id)
        )?.length > 0,
      submenuItems: [
        {
          name: "Lab",
          href: "/lab",
          icon: BuildingOffice2Icon,
          current: false,
          is_permitted:
            permissions?.filter(
              (p) =>
                p.permission_name_en == "Hospital Admin" &&
                p.permission_id == 135
            )?.length > 0,
        },
        {
          name: "Category",
          href: "/category",
          icon: CircleStackIcon,
          current: false,
          is_permitted:
            permissions?.filter(
              (p) =>
                p.permission_name_en == "Category Admin" &&
                p.permission_id == 136
            )?.length > 0,
        },
        {
          name: "Test",
          href: "/test",
          icon: ClipboardDocumentCheckIcon,
          current: false,
          is_permitted:
            permissions?.filter(
              (p) =>
                p.permission_name_en == "Test Admin" && p.permission_id == 126
            )?.length > 0,
        },
        {
          name: "Package",
          href: "/package",
          icon: WalletIcon,
          current: false,
          is_permitted:
            permissions?.filter(
              (p) =>
                p.permission_name_en == "Package Admin" &&
                p.permission_id == 127
            )?.length > 0,
        },
        {
          name: "Reference Ranges",
          href: "/reference-ranges",
          icon: WalletIcon,
          current: false,
          is_permitted:
            permissions?.filter(
              (p) =>
                p.permission_name_en == "Reference Range Admin" &&
                p.permission_id == 137
            )?.length > 0,
        },
        {
          name: "Questionnaire",
          href: "/questionnaire-list",
          icon: ClipboardDocumentCheckIcon,
          current: false,
          is_permitted:
            permissions?.filter(
              (p) =>
                p.permission_name_en == "Questionnaire Admin" &&
                p.permission_id == 142
            )?.length > 0,
        },
        // {
        //   name: "Units",
        //   href: "/m-units",
        //   icon: GlobeAltIcon,
        //   current: false,
        //   is_permitted: true,
        // },
      ],
    },
    {
      name: "Reservation",
      icon: AdjustmentsHorizontalIcon,
      submenu: true,
      current: false,
      is_permitted:
        permissions?.filter((p) =>
          [128, 129, 130, 131].includes(p.permission_id)
        )?.length > 0,
      submenuItems: [
        {
          name: "Add Reservation",
          href: "/reservation",
          icon: CalendarDaysIcon,
          current: false,
          is_permitted:
            permissions?.filter(
              (p) =>
                p.permission_name_en == "Reservation Admin" &&
                p.permission_id == 128
            )?.length > 0,
        },
        {
          name: "Reservation List",
          href: "/reservationlist",
          icon: CalendarDaysIcon,
          current: false,
          is_permitted:
            permissions?.filter(
              (p) =>
                p.permission_name_en == "Reservation List Admin" &&
                p.permission_id == 129
            )?.length > 0,
        },
        {
          name: "Sample List",
          href: "/samplelist",
          icon: BeakerIcon,
          current: false,
          is_permitted:
            permissions?.filter(
              (p) =>
                p.permission_name_en == "Sample List Admin" &&
                p.permission_id == 130
            )?.length > 0,
        },
        {
          name: "Sample Processing",
          href: "/sample-processing-tab",
          icon: WalletIcon,
          current: false,
          is_permitted:
            permissions?.filter(
              (p) =>
                p.permission_name_en == "Sample Processing Admin" &&
                p.permission_id == 131
            )?.length > 0,
        },
        {
          name: "Receive Samples",
          href: "/receive-samples",
          icon: WalletIcon,
          current: false,
          is_permitted:
            permissions?.filter(
              (p) =>
                p.permission_name_en == "Reservation List Admin" &&
                p.permission_id == 130
            )?.length > 0,
        },
        {
          name: "Refunded List",
          href: "/refundedlist",
          icon: CalendarDaysIcon,
          current: false,
          is_permitted:
            permissions?.filter(
              (p) =>
                p.permission_name_en == "Reservation List Admin" &&
                p.permission_id == 130
            )?.length > 0,
        },
        {
          name: "Rejected List",
          href: "/rejectedlist",
          icon: CalendarDaysIcon,
          current: false,
          is_permitted:
            permissions?.filter(
              (p) =>
                p.permission_name_en == "Reservation List Admin" &&
                p.permission_id == 130
            )?.length > 0,
        },
        {
          name: "Invoices List",
          href: "/invoiceslist",
          icon: CalendarDaysIcon,
          current: false,
          is_permitted:
            permissions?.filter(
              (p) =>
                p.permission_name_en == "Reservation List Admin" &&
                p.permission_id == 130
            )?.length > 0,
        },
      ],
    },
    {
      name: "Outsourced",
      submenu: true,
      current: false,
      icon: AdjustmentsHorizontalIcon,
      is_permitted: userInfo && userInfo.role_en && typeof userInfo.role_en === "string" && userInfo.role_en.toLowerCase() === "lab manager" ? false :
        permissions?.filter((p) => [138, 145, 141].includes(p.permission_id))
          ?.length > 0,
      submenuItems: [
        {
          name: "Clinic",
          href: "/outsourced-clinic",
          icon: BuildingOffice2Icon,
          current: false,
          is_permitted:
            permissions?.filter(
              (p) =>
                p.permission_name_en == "Clinic Admin" && p.permission_id == 138
            )?.length > 0,
        },
        // {
        // 	name: "Contract Pricing",
        // 	href: "/contract-pricing",
        // 	icon: WalletIcon,
        // 	current: false,
        // 	is_permitted: true
        // },
        {
          name: "Price List",
          href: "/price-list",
          icon: RiyalIcon,
          current: false,
          is_permitted:
            permissions?.filter(
              (p) =>
                p.permission_name_en == "Price List Admin" &&
                p.permission_id == 145
            )?.length > 0,
        },
        {
          name: "Invoice",
          href: "/invoice",
          icon: ClipboardDocumentCheckIcon,
          current: false,
          is_permitted:
            permissions?.filter(
              (p) =>
                p.permission_name_en == "Invoice Admin" &&
                p.permission_id == 141
            )?.length > 0,
        },
        {
          name: "Outsource Clinic Logs",
          href: "/outsourced-clinic-logs",
          icon: BuildingOffice2Icon,
          current: false,
          is_permitted: true,
        },
        {
          name: "Outsource Clinic Patients",
          href: "/outsourced-clinic-patients",
          icon: BuildingOffice2Icon,
          current: false,
          is_permitted: true,
        },
      ],
    },
    {
      name: "Master",
      submenu: true,
      current: false,
      icon: AdjustmentsHorizontalIcon,
      is_permitted:
        permissions?.filter((p) =>
          [
            143, 144, 146, 147, 148, 149, 150, 151, 152, 171, 172, 173, 174,
            175, 176, 177,
          ].includes(p.permission_id)
        )?.length > 0,
      submenuItems: [
        {
          name: "VAT Master",
          href: "/global-setting",
          icon: AdjustmentsHorizontalIcon,
          current: false,
          is_permitted:
            permissions?.filter(
              (p) =>
                p.permission_name_en == "Vat Master Admin" &&
                p.permission_id == 143
            )?.length > 0,
        },
        // {
        //   name: "Super Master",
        //   href: "/super-master",
        //   icon: AdjustmentsHorizontalIcon,
        //   current: false,
        //   is_permitted:
        //     permissions?.filter(
        //       (p) =>
        //         p.permission_name_en == "Super Master Admin" &&
        //         p.permission_id == 144
        //     )?.length > 0,
        // },
        {
          name: "Gender",
          href: "/gender",
          icon: UserIcon,
          current: false,
          is_permitted:
            permissions?.filter(
              (p) =>
                p.permission_name_en == "Gender Admin" && p.permission_id == 146
            )?.length > 0,
        },
        {
          name: "Country",
          href: "/country",
          icon: GlobeAltIcon,
          current: false,
          is_permitted:
            permissions?.filter(
              (p) =>
                p.permission_name_en == "Country Admin" &&
                p.permission_id == 147
            )?.length > 0,
        },
        {
          name: "State",
          href: "/state",
          icon: BuildingLibraryIcon,
          current: false,
          is_permitted:
            permissions?.filter(
              (p) =>
                p.permission_name_en == "State Admin" && p.permission_id == 148
            )?.length > 0,
        },
        {
          name: "City",
          href: "/city",
          icon: BuildingOffice2Icon,
          current: false,
          is_permitted:
            permissions?.filter(
              (p) =>
                p.permission_name_en == "City Admin" && p.permission_id == 149
            )?.length > 0,
        },
        {
          name: "Lab Analyzer",
          href: "/lab-analyzer",
          icon: BeakerIcon,
          current: false,
          is_permitted:
            permissions?.filter(
              (p) =>
                p.permission_name_en == "Lab analyzer Admin" &&
                p.permission_id == 150
            )?.length > 0,
        },
        // {
        //   name: "Programmable Comments",
        //   href: "/prog-comments",
        //   icon: ViewfinderCircleIcon,
        //   current: false,
        //   is_permitted:
        //     permissions?.filter(
        //       (p) =>
        //         p.permission_name_en == "Programmable comments Admin" &&
        //         p.permission_id == 151
        //     )?.length > 0,
        // },
        {
          name: "Coupons",
          href: "/coupons",
          icon: RiyalIcon,
          current: false,
          is_permitted:
            permissions?.filter(
              (p) =>
                p.permission_name_en == "Coupon Admin" && p.permission_id == 152
            )?.length > 0,
        },
        {
          name: "Test Color",
          href: "/test-color",
          icon: PaintBrushIcon,
          current: false,
          is_permitted:
            permissions?.filter(
              (p) =>
                p.permission_name_en == "Test Color Admin" &&
                p.permission_id == 171
            )?.length > 0,
        },
        {
          name: "Region Master",
          href: "/region",
          icon: AdjustmentsHorizontalIcon,
          current: false,
          is_permitted:
            permissions?.filter(
              (p) =>
                p.permission_name_en == "Region Admin" && p.permission_id == 172
            )?.length > 0,
        },
        {
          name: "Sample Type Master",
          href: "/sample-type",
          icon: AdjustmentsHorizontalIcon,
          current: false,
          is_permitted:
            permissions?.filter(
              (p) =>
                p.permission_name_en == "Sample Type Admin" &&
                p.permission_id == 173
            )?.length > 0,
        },
        {
          name: "Clinic Refferal",
          href: "/clinic-refferal",
          icon: AdjustmentsHorizontalIcon,
          current: false,
          is_permitted:
            permissions?.filter(
              (p) =>
                p.permission_name_en == "Clinic Refferal Admin" &&
                p.permission_id == 174
            )?.length > 0,
        },
        {
          name: "Item Category",
          href: "/warehouse-item-category",
          icon: AdjustmentsHorizontalIcon,
          current: false,
          is_permitted: true,
        },
        {
          name: "User Access List",
          href: "/user-access-list",
          icon: AdjustmentsHorizontalIcon,
          current: false,
          is_permitted:
            permissions?.filter(
              (p) =>
                p.permission_name_en == "User Access Admin" &&
                p.permission_id == 175
            )?.length > 0,
        },
        {
          name: "Incentive Dashboard",
          href: "/incentive-dashboard",
          icon: ChartPieIcon,
          current: true,
          is_permitted:
            permissions?.filter(
              (p) =>
                p.permission_name_en == "Incentive Dashboard Admin" &&
                p.permission_id == 176
            )?.length > 0,
        },
        {
          name: "Methodology",
          href: "/methodologies",
          icon: GlobeAltIcon,
          current: false,
          is_permitted:
            permissions?.filter(
              (p) =>
                p.permission_name_en == "Methodology Admin" &&
                p.permission_id == 177
            )?.length > 0,
        },
        {
          name: "Organisms",
          href: "/organisms",
          icon: GlobeAltIcon,
          current: false,
          is_permitted: true,
        },
        {
          name: "Antibiotics",
          href: "/antibiotics",
          icon: GlobeAltIcon,
          current: false,
          is_permitted: true,
        },
        {
          name: "Antibiotic Result Types",
          href: "/antibiotic-result-types",
          icon: GlobeAltIcon,
          current: false,
          is_permitted: true,
        },
        {
          name: "Shifts",
          href: "/shift",
          icon: ClockIcon,
          current: false,
          is_permitted: true,
        },
        {
          name: "User shifts",
          href: "/user-shift",
          icon: UserCircleIcon,
          current: false,
          is_permitted: true,
        },
        {
          name: "Department",
          href: "/department",
          icon: TagIcon,
          current: false,
          is_permitted: true,
        },
        {
          name: "User departments",
          href: "/user-department",
          icon: UserCircleIcon,
          current: false,
          is_permitted: true,
        },
      ],
    },
    {
      name: "QC",
      submenu: true,
      current: false,
      icon: AdjustmentsHorizontalIcon,
      is_permitted:
        permissions?.filter((p) => [158].includes(p.permission_id))?.length > 0,
      submenuItems: [
        {
          name: "QC List",
          href: "/qc/control-list",
          icon: MapPinIcon,
          current: false,
          is_permitted:
            permissions?.filter(
              (p) =>
                p.permission_name_en == "Control list Admin" &&
                p.permission_id == 158
            )?.length > 0,
        },
        {
          name: "QC Result",
          href: "/quality-control",
          icon: BeakerIcon,
          current: false,
          is_permitted: true,
        },
      ],
    },
    {
      name: "Warehouse",
      submenu: true,
      current: false,
      icon: AdjustmentsHorizontalIcon,
      is_permitted:
        permissions?.filter((p) =>
          [159, 160, 161, 153, 154, 155, 156, 157, 162].includes(
            p.permission_id
          )
        )?.length > 0,
      submenuItems: [
        {
          name: "Items",
          href: "/items",
          icon: MapPinIcon,
          current: false,
          is_permitted:
            permissions?.filter(
              (p) =>
                p.permission_name_en == "Items Admin" && p.permission_id == 159
            )?.length > 0,
        },
        {
          name: "Units",
          href: "/units",
          icon: MapPinIcon,
          current: false,
          is_permitted:
            permissions?.filter(
              (p) =>
                p.permission_name_en == "Units Admin" && p.permission_id == 160
            )?.length > 0,
        },
        {
          name: "Vendor",
          href: "/vendors",
          icon: MapPinIcon,
          current: false,
          is_permitted:
            permissions?.filter(
              (p) =>
                p.permission_name_en == "Vendor Admin" && p.permission_id == 161
            )?.length > 0,
        },
        {
          name: "Storage Locations ",
          href: "/storage-locations",
          icon: ViewfinderCircleIcon,
          current: false,
          is_permitted:
            permissions?.filter(
              (p) =>
                p.permission_name_en == "Storage locations Admin" &&
                p.permission_id == 153
            )?.length > 0,
        },
        {
          name: "Stock",
          href: "/stock",
          icon: ViewfinderCircleIcon,
          current: false,
          is_permitted:
            permissions?.filter(
              (p) =>
                p.permission_name_en == "Storage locations items Admin" &&
                p.permission_id == 154
            )?.length > 0,
        },
        {
          name: "Movement",
          href: "/movements",
          icon: ViewfinderCircleIcon,
          current: false,
          is_permitted:
            permissions?.filter(
              (p) =>
                p.permission_name_en == "Movement Admin" &&
                p.permission_id == 155
            )?.length > 0,
        },
        {
          name: "GRN (Goods Received)",
          href: "/goods-received",
          icon: ViewfinderCircleIcon,
          current: false,
          is_permitted:
            permissions?.filter(
              (p) =>
                p.permission_name_en == "GRN (Goods received) Admin" &&
                p.permission_id == 156
            )?.length > 0,
        },
        {
          name: "GRN (Goods Return)",
          href: "/goods-return",
          icon: ViewfinderCircleIcon,
          current: false,
          is_permitted:
            permissions?.filter(
              (p) =>
                p.permission_name_en == "GRN (Good returns) Admin" &&
                p.permission_id == 157
            )?.length > 0,
        },
        {
          name: "Purchase Order",
          href: "/purchase_order",
          icon: AdjustmentsHorizontalIcon,
          current: false,
          is_permitted:
            permissions?.filter(
              (p) =>
                p.permission_name_en == "Purchase order Admin" &&
                p.permission_id == 162
            )?.length > 0,
        },
      ],
    },
    {
      name: "Global Settings",
      href: "/settings",
      icon: AdjustmentsHorizontalIcon,
      current: true,
      is_permitted:
        permissions?.filter(
          (p) =>
            p.permission_name_en == "Global Settings Admin" &&
            p.permission_id == 178
        )?.length > 0,
    },
    {
      name: "Reports",
      submenu: true,
      current: false,
      icon: DocumentChartBarIcon,
      is_permitted: (res_role_id == 27 || res_role_id == "27") ? false :
        permissions?.filter((p) =>
          [170, 163, 164, 165, 166, 167, 168, 169].includes(p.permission_id)
        )?.length > 0,
      submenuItems: [
        {
          name: "Coupon Report",
          href: "/coupon-report",
          icon: RiyalIcon,
          current: false,
          is_permitted:
            permissions?.filter(
              (p) =>
                (p.permission_name_en == "Coupon Report" ||
                  p.permission_name_en == "Reports Admin") &&
                (p.permission_id == 163 || p.permission_id == 170)
            )?.length > 0,
        },
        {
          name: "Patient Report",
          href: "/res-patient-report",
          icon: DocumentChartBarIcon,
          current: false,
          is_permitted:
            permissions?.filter(
              (p) =>
                (p.permission_name_en == "Patient Report" ||
                  p.permission_name_en == "Reports Admin") &&
                (p.permission_id == 164 || p.permission_id == 170)
            )?.length > 0,
        },
        {
          name: "Incentive Report",
          href: "/incentive-report",
          icon: RiyalIcon,
          current: false,
          is_permitted: userInfo && userInfo.role_en && typeof userInfo.role_en === "string" && userInfo.role_en.toLowerCase() === "lab manager" ? false :
            permissions?.filter(
              (p) =>
                (p.permission_name_en == "Incentive Report" ||
                  p.permission_name_en == "Reports Admin") &&
                (p.permission_id == 165 || p.permission_id == 170)
            )?.length > 0,
        },

        {
          name: "Reservation Transaction",
          href: "/reservation-trnx",
          icon: CalendarDaysIcon,
          current: false,
          is_permitted:
            permissions?.filter(
              (p) =>
                (p.permission_name_en == "Reservation Transaction Report" ||
                  p.permission_name_en == "Reports Admin") &&
                (p.permission_id == 166 || p.permission_id == 170)
            )?.length > 0,
        },
        {
          name: "Accumulated Revenue Report",
          href: "/accumulated-revenue-report",
          icon: CalendarDaysIcon,
          current: false,
          is_permitted: userInfo && userInfo.role_en && typeof userInfo.role_en === "string" && userInfo.role_en.toLowerCase() === "lab manager" ? false :
            permissions?.filter(
              (p) =>
                (p.permission_name_en == "Accumulated Revenue Report" ||
                  p.permission_name_en == "Reports Admin") &&
                (p.permission_id == 167 || p.permission_id == 170)
            )?.length > 0,
        },
        {
          name: "Monthly Revenue Report",
          href: "/monthly-revenue-report",
          icon: CalendarDaysIcon,
          current: false,
          is_permitted: userInfo && userInfo.role_en && typeof userInfo.role_en === "string" && userInfo.role_en.toLowerCase() === "lab manager" ? false :
            permissions?.filter(
              (p) =>
                (p.permission_name_en == "Monthly Revenue Report" ||
                  p.permission_name_en == "Reports Admin") &&
                (p.permission_id == 168 || p.permission_id == 170)
            )?.length > 0,
        },
        {
          name: "All Logs",
          href: "/all-logs",
          icon: CalendarDaysIcon,
          current: false,
          is_permitted: userInfo && userInfo.role_en && typeof userInfo.role_en === "string" && userInfo.role_en.toLowerCase() === "lab manager" ? false :
            permissions?.filter(
              (p) =>
                (p.permission_name_en == "All Logs" ||
                  p.permission_name_en == "Reports Admin") &&
                (p.permission_id == 169 || p.permission_id == 170)
            )?.length > 0,
        },
        {
          name: "Urgent Logs",
          href: "/urgent-logs",
          icon: CalendarDaysIcon,
          current: false,
          is_permitted: userInfo && userInfo.role_en && typeof userInfo.role_en === "string" && userInfo.role_en.toLowerCase() === "lab manager" ? false :
            permissions?.filter(
              (p) =>
                (p.permission_name_en == "All Logs" ||
                  p.permission_name_en == "Reports Admin") &&
                (p.permission_id == 169 || p.permission_id == 170)
            )?.length > 0,
        },
        {
          name: "Location Revenue Report",
          href: "/location-revenue",
          icon: RiyalIcon,
          current: false,
          is_permitted: userInfo && userInfo.role_en && typeof userInfo.role_en === "string" && userInfo.role_en.toLowerCase() === "lab manager" ? false : true,
        },

        // outsource Clinic Report Section Start
        {
          name: "OT Reservation Report",
          href: "/ot-reservation-report",
          icon: CalendarDaysIcon,
          current: false,
          is_permitted: userInfo && userInfo.role_en && typeof userInfo.role_en === "string" && userInfo.role_en.toLowerCase() === "lab manager" ? false :
            permissions?.filter(
              (p) =>
                (p.permission_name_en == "OT Reservation Report" ||
                  p.permission_name_en == "Reports Admin") &&
                (p.permission_id == 166 || p.permission_id == 170)
            )?.length > 0,
        },
        {
          name: "OT Test Report",
          href: "/ot-test-report",
          icon: CalendarDaysIcon,
          current: false,
          is_permitted: userInfo && userInfo.role_en && typeof userInfo.role_en === "string" && userInfo.role_en.toLowerCase() === "lab manager" ? false :
            permissions?.filter(
              (p) =>
                (p.permission_name_en == "OT Test Report" ||
                  p.permission_name_en == "Reports Admin") &&
                (p.permission_id == 166 || p.permission_id == 170)
            )?.length > 0,
        },
        {
          name: "OT Patient Report",
          href: "/ot-patient-report",
          icon: CalendarDaysIcon,
          current: false,
          is_permitted: userInfo && userInfo.role_en && typeof userInfo.role_en === "string" && userInfo.role_en.toLowerCase() === "lab manager" ? false :
            permissions?.filter(
              (p) =>
                (p.permission_name_en == "OT Patient Report" ||
                  p.permission_name_en == "Reports Admin") &&
                (p.permission_id == 166 || p.permission_id == 170)
            )?.length > 0,
        },
        // outsource Clinic Report Section End
      ],
    },
    {
      name: "CRM",
      submenu: true,
      current: false,
      icon: AdjustmentsHorizontalIcon,
      is_permitted: (res_role_id == 27 || res_role_id == "27" || res_role_id == 29 || res_role_id == "29") ? true :
        permissions?.filter((p) => [163].includes(p.permission_id))?.length > 0,
      submenuItems: [
        {
          name: "Test Package List",
          href: "/test-package-list",
          icon: BuildingOffice2Icon,
          current: false,
          is_permitted: (res_role_id == 27 || res_role_id == "27" || res_role_id == 29 || res_role_id == "29") ? false : true,
        },
        {
          name: "Report PDF Generated",
          href: "/report-pdf-generated",
          icon: RiyalIcon,
          current: false,
          is_permitted: (res_role_id == 29 || res_role_id == "29") ? true :
            permissions?.filter(
              (p) =>
                (p.permission_name_en == "Coupon Report" ||
                  p.permission_name_en == "Reports Admin") &&
                (p.permission_id == 163 || p.permission_id == 170)
            )?.length > 0,
        },
        {
          name: "Sample Status",
          href: "/crm-sample-processing",
          icon: BuildingOffice2Icon,
          current: false,
          is_permitted: (res_role_id == 29 || res_role_id == "29") ? false : true,
        },
      ],
    },
  ];

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [desktopSidebar, setDesktopSidebar] = useState(true);
  // console.log(desktopSidebar);
  const [sysModule, setSysModule] = useState([]);
  const [userLocDrop, setUserLocDrop] = useState([]);
  const [userLocData, setUserLocData] = useState([]);
  const [userFilterLocationId, setUserFilterLocationId] = useState([]);

  const [notificationUnreadCount, setNotificationUnreadCount] = useState(0);
  const {
    loginUserInfo: { userData },
  } = useSelector((state) => state);
  const [submenuOpen, setsubmenuOpen] = useState({
    appointment: false,
    forms: false,
    master: false,
    settings: false,
  });

  console.log(window.location.href, "window.location.href");
  localStorage.setItem("lastVisitedUrl", window.location.pathname)
  useEffect(() => {
    (async function () {
      setLoading(true);
      const getSysModule = JSON.parse(localStorage.getItem("module"));
      const user_loc_menus = JSON.parse(localStorage.getItem("user_loc_menus"));
      const userLocData = JSON.parse(localStorage.getItem("userLocData"));
      console.log("user_loc_menus=>", user_loc_menus);
      setUserLocDrop(user_loc_menus);
      setUserLocData(userLocData);
      getSysModule ? setSysModule(getSysModule) : setSysModule([]);
      dispatch(getLoginUserInfo("All"));
      dispatch(getUsersList());
      // await setInterval(async () => {
      // 	const { data } = await MasterServices.getUnreadNotificationCount();
      // 	setNotificationUnreadCount(data.data);
      // }, 15000);
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    setupInactivityTimer(dispatch, navigate);
  }, [dispatch, navigate]);

  const onLogout = () => {
    dispatch(handleLogout(navigate));
    Navigate("/login");
  };

  const updateUserLoc = (payload) => {
    if (payload.label && payload.value) {
      let body = {
        label: payload.label,
        value: payload.value,
        loc_branch_code: payload.loc_branch_code,
      };
      MasterServices.updateUserLoc(body)
        .then((response) => {
          const { data, status } = response;
          if (status == 200 && data?.status) {
            localStorage.setItem("user", JSON.stringify(data.data.user));
            localStorage.setItem(
              "favourites",
              JSON.stringify(data.data.favourites)
            );
            localStorage.setItem("module", JSON.stringify(data.data.module));
            
					localStorage.setItem("user_loc_menus", data?.data?.user_loc_menus ? JSON.stringify([...data.data.user_loc_menus,{ label: "All", value: "All", }]) : JSON.stringify([{ label: "All", value: "All", }]));
            localStorage.setItem("userLocData", JSON.stringify(data.data.userLocData));
            localStorage.setItem(
              "userLocData",
              data.data.userLocData[0]?.value ? JSON.stringify(data.data.userLocData) : JSON.stringify([{ "value": "All", "label": "All", "loc_branch_code": "" }])
            );
            localStorage.setItem("token", data.data.token);
            localStorage.setItem("TBB_role_id", data.data.user.role_id);
            window.location.reload();
          }
        })
        .catch((error) => {
          const msg = error;
        });
    }
  };

  const handleProfile = () => {
    console.log("nitesh");
  };

  const userNavigation = [
    { name: "Profile", href: "/profile", onclick: handleProfile, click: false },
    { name: "Logout", href: "#", onclick: onLogout, click: true },
  ];

  const [query, setQuery] = useState("");

  const [searchModal, setSearchModal] = useState(false);

  const filteredMenus =
    query === ""
      ? []
      : sysModule.filter((data) => {
        return data.module_name.toLowerCase().includes(query.toLowerCase());
      });

  const navigatePage = (routeData) => {
    setSearchModal(false);
    navigate(routeData.module_key);
  };

  return (
    <>
      <ToastContainer delay={3000} position="top-right" />
      {locationLoader ? <FallingLinesLoader /> :
      <div className="">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 md:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-cyan-700 pt-5 pb-4">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex flex-shrink-0 items-center px-4">
                    <img
                      className="h-8 w-auto"
                      src="https://api-beta.mylabib.com/images/logo2.png"
                      alt="Labib"
                    />
                  </div>
                  <div className="mt-5 h-0 flex-1 overflow-y-auto">
                    <nav className="space-y-1 px-2">
                      {Menus.map((menu, index) =>
                        menu.is_permitted ? (
                          <div key={menu.name}>
                            <NavLink
                              to={menu.href}
                              // onClick={() => {
                              //   menu.submenu && setsubmenuOpen(!submenuOpen);
                              // }}
                              className={classNames(
                                menu.current
                                  ? "bg-cyan-800 text-white"
                                  : "text-cyan-100 hover:bg-cyan-600",
                                "group flex items-center px-2 py-2  font-medium rounded-md"
                              )}
                            >
                              <menu.icon
                                className="mr-3 h-6 w-6 flex-shrink-0 text-cyan-300"
                                aria-hidden="true"
                              />
                              {menu.name}
                              {menu.submenu && (
                                <ChevronDownIcon
                                  className={` ${submenuOpen && "-rotate-0"
                                    } mr-3 h-6 w-6 flex-shrink-0 -rotate-90 text-cyan-300 duration-150`}
                                  aria-hidden="true"
                                />
                              )}
                            </NavLink>
                            {menu.submenu && (
                              <div>
                                {menu.submenuItems.map((submenuItem, index) =>
                                  submenuItem.is_permitted ? (
                                    <Link
                                      key={submenuItem.name}
                                      to={submenuItem.href}
                                      className={classNames(
                                        menu.current
                                          ? "bg-cyan-800 text-white"
                                          : "text-cyan-100 hover:bg-cyan-600",
                                        "group flex items-center  px-2 py-2 text-xs font-normal rounded-md"
                                      )}
                                    >
                                      <submenuItem.icon
                                        className="ml-5 mr-3 h-5 w-6 flex-shrink-0 text-cyan-300"
                                        aria-hidden="true"
                                      />
                                      {submenuItem.name}
                                    </Link>
                                  ) : null
                                )}
                              </div>
                            )}
                          </div>
                        ) : null
                      )}
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0" aria-hidden="true">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <div
          className={`fixed inset-0 flex  transform ${desktopSidebar ? "translate-x-0 z-0" : "-translate-x-full"
            } transition-transform duration-500 ease-in-out`}
        >
          {/* <div
            className="fixed inset-0 bg-transparent"
            onClick={() => setDesktopSidebar(false)}
          ></div> */}
          <div className="md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col bg-cyan-700 pt-5 hidden">
            <div className="flex flex-grow flex-col hide-scrollbar overflow-y-auto">
              <div className="flex flex-shrink-0 items-center px-4">
                <img
                  className="h-10 w-auto"
                  src={`${BASEURL}/images/logo2.png`}
                  alt="Tabiib"
                />
                <button
                  onClick={() => setDesktopSidebar(false)}
                  className="ml-auto p-2 text-cyan-300 hover:text-cyan-100"
                >
                  <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="mt-5 hide-scrollbar flex flex-1 flex-col">
                <nav className="flex-1 hide-scrollbar space-y-1 px-2 pb-4">
                  {Menus.map((menu, index) =>
                    menu.is_permitted ? (
                      <div key={menu.name}>
                        <Link
                          to={menu.href}
                          onClick={() => {
                            setsubmenuOpen({
                              ...submenuOpen,
                              [menu.name.toLowerCase()]:
                                !submenuOpen[menu.name.toLowerCase()],
                            });
                          }}
                          className={classNames(
                            window.location.pathname === menu.href
                              ? "bg-cyan-800 text-white"
                              : "text-cyan-100 hover:bg-cyan-600",
                            "group flex items-center px-2 py-2 font-medium rounded-md"
                          )}
                        >
                          <menu.icon
                            className="mr-3 h-6 w-6 flex-shrink-0 text-cyan-300"
                            aria-hidden="true"
                          />
                          {menu.name}
                          {menu.submenu && (
                            <ChevronDownIcon
                              className={`${submenuOpen[menu.name.toLowerCase()]
                                ? "-rotate-0"
                                : "-rotate-90"
                                } mr-3 h-6 w-6 flex-shrink-0 text-cyan-300 duration-150`}
                              aria-hidden="true"
                            />
                          )}
                        </Link>
                        {menu.submenu &&
                          submenuOpen[menu.name.toLowerCase()] && (
                            <div>
                              {menu.submenuItems.map(
                                (submenuItem, index) =>
                                  submenuItem.is_permitted && (
                                    <Link
                                      to={submenuItem.href}
                                      className={classNames(
                                        window.location.pathname ===
                                          submenuItem.href
                                          ? "bg-cyan-800 text-white"
                                          : "text-cyan-100 hover:bg-cyan-600",
                                        "group flex items-center px-2 py-2 font-medium rounded-md"
                                      )}
                                      key={submenuItem.name}
                                    >
                                      <submenuItem.icon
                                        className="mr-3 h-5 w-6 flex-shrink-0 text-cyan-300"
                                        aria-hidden="true"
                                      />
                                      {submenuItem.name}
                                    </Link>
                                  )
                              )}
                            </div>
                          )}
                      </div>
                    ) : null
                  )}
                </nav>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`flex flex-1 flex-col ${desktopSidebar ? "md:ml-64" : ""}`}
        >
          <div className="sticky top-0 z-20 flex h-16 flex-shrink-0 bg-white shadow">
            <button
              type="button"
              className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-500 md:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
            </button>
            <button
              type="button"
              className={`${desktopSidebar ? "hidden" : "md:flex items-center"
                } border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-500 hidden `}
              onClick={() => setDesktopSidebar(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
            </button>
            <div className="flex flex-1 justify-between px-4">
              <div className="flex flex-1 items-center">
                <div className="w-8">
                  <MagnifyingGlassIcon
                    size={18}
                    className="cursor-pointer"
                    onClick={() => setSearchModal(true)}
                  />
                </div>
                {/* CAN PUT CONTENT HERE INSTEAD OF SEARCH BAR */}
              </div>
              {/* <div className="flex flex-1"> */}
              {/* CAN PUT CONTENT HERE INSTEAD OF SEARCH BAR */}
              {/* </div> */}
              <div className="ml-4 flex items-center md:ml-6">
                {loading ? (
                  <FallingLinesLoader />
                ) : (
                  <>
                    {userLocDrop && userLocDrop.length > 0 ? (

                      <div className="mt-1 pt-2 pb-2 sm:col-span-2 sm:mt-0 w-80">
                        {userFilterLocationId}
											{/* {userLocData} */}
                        <Select
                          name="loc_name_en"
                          id="loc_id"
                          placeholder="Select Locations"
                          value={userLocData}
                          onChange={(e) => {
                            setLocationLoader(true)
                            setUserFilterLocationId(e);
                            updateUserLoc(e);
                          }}
                          components={animatedComponents}
                          options={userLocDrop}
                          classNamePrefix="select"
                          className="basic-multi-select block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                        />
                      </div>
                    ) : null}
                  </>
                )}

                {/* Notification Dropdown */}
                <Menu as="div" className="relative ml-3 mr-3">
                  <div>
                    <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2">
                      <span className="sr-only">Open user menu</span>
                      <div className="w-7">
                        {notificationUnreadCount > 0 && (
                          <span className="bg-red-500 text-white rounded-full absolute top-[-12px] left-0 p-0.5 text-xs w-5 h-5">
                            {notificationUnreadCount}
                          </span>
                        )}
                        <BellIcon size={18} />
                      </div>
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      style={{ width: "500px" }}
                      className="absolute right-0 z-10 mt-2 max-w-2xl origin-top-right rounded-md bg-white p-5 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                    >
                      <Menu.Item>
                        {({ close }) => <Notification onClose={close} />}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>

                <div className="text-end leading-none">
                  <p>{userData?.user_first_name}</p>
                  <i className="text-xs">
                    {"(" + userData?.m_role_relation?.role_name_en + ")"}
                  </i>
                </div>

                {/* Profile dropdown */}
                <Menu as="div" className="relative ml-3">
                  <div>
                    <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2">
                      <span className="sr-only">Open user menu</span>
                      <span className="h-8 w-8 overflow-hidden rounded-full bg-gray-100">
                        {userData && userData.user_profile_image ? (
                          <img
                            src={userData.user_profile_image}
                            alt="profile_icon"
                          />
                        ) : (
                          <svg
                            className="h-full w-full text-gray-300"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                          </svg>
                        )}
                      </span>
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {userNavigation.map((item, key) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <Link
                              onClick={item.click ? item.onclick : null}
                              to={item.click ? null : item.href}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                            >
                              {item.name}
                            </Link>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <main className="flex-1 z-10">
            <div className="py-6">
              {/* max-w-[150rem] */}
              <div className="mx-auto max-w-[150rem] overflow-x-hidden px-4 sm:px-6 md:px-8">
                {children}
              </div>
            </div>
          </main>

          <Transition.Root
            show={searchModal}
            as={Fragment}
            afterLeave={() => setQuery("")}
            appear
          >
            <Dialog as="div" className="relative z-20" onClose={setSearchModal}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
              </Transition.Child>

              <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="mx-auto max-w-xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                    <Combobox
                      //onChange={(person) => (window.location = person.url)}
                      onChange={(person) => navigatePage(person)}
                    >
                      <div className="relative">
                        <MagnifyingGlassIcon
                          className="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        <Combobox.Input
                          className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 sm:text-sm"
                          placeholder="Search..."
                          onChange={(event) => setQuery(event.target.value)}
                        />
                      </div>

                      {filteredMenus.length > 0 && (
                        <Combobox.Options
                          static
                          className="max-h-72 scroll-py-2 overflow-y-auto py-2 text-sm text-gray-800"
                        >
                          {filteredMenus.map((person, key) => (
                            <Combobox.Option
                              key={person.module_name}
                              value={person}
                              className={({ active }) =>
                                classNames(
                                  "cursor-pointer select-none px-4 py-2",
                                  active && "bg-cyan-600 text-white"
                                )
                              }
                            >
                              {person.module_name}
                            </Combobox.Option>
                          ))}
                        </Combobox.Options>
                      )}

                      {query !== "" && filteredMenus.length === 0 && (
                        <p className="p-4 text-sm text-gray-500">
                          No search found.
                        </p>
                      )}
                    </Combobox>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>
        </div>
      </div>}
    </>
  );
};

export default DashboardLayout;
