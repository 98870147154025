import React, { useEffect, useState, Fragment } from "react";
import Breadcrumb from "../../../components/Breadcrumb";
import CustomButton from "../../../components/common/CustomButton";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { useFormik } from "formik";
import Select from "react-select";
import { userDepartmentSchema } from "../../../schemas";
import MasterServices from "../../../ApiServices/MasterServices";
import { toast } from "react-toast";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Table from "../../../components/tables/table";
import { user_department_columns } from "../../../components/tables/tableheader";
import ConfirmationModal from "../../../components/ConfirmationModal";
import Api from "../../../ApiServices/Api";

const UserDepartment = () => {
  const pages = [
    { title: "Departments", href: "/department", module_id: 50 },
    { title: "User Department", href: "/user-department", module_id: 51 },
  ]; // TODO: module_id
  const initialValues = {
    user_id: "",
    department_id: "",
  };

  const [formData, setFormData] = useState(initialValues);
  const [data, setData] = useState([]);
  const [modalOpenFlage, setmodalOpenFlage] = useState(false);

  const [userList, setUserList] = useState(false);
  const [departmentData, setDepartmentData] = useState([]);

  const [userLoading, setUserLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [id, setId] = useState();
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    id: null,
  });

  const [selectedUsers, setSelectedUsers] = useState(false);
  const [selectedDept, setSelectedDept] = useState(false);

  useEffect(() => {
    document.title = "Labib |  User Department";
    getAllUsers();
    getAllDepartments();
    getAll();
  }, []);

  const getAll = async () => {
    try {
      setIsLoading(true);
      const { data } = await Api().get("/api/dashboard/user-department");
      if (data.status) {
        setData(data.data);
        toast.success(data.data.message);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getAllDepartments = async () => {
    try {
      setIsLoading(true);
      const { data } = await Api().get("/api/dashboard/department");
      if (data.status) {
        let newUsers = data.data;
        let users = newUsers.map((el) => {
          let obj = { ...el, label: el.name, value: el.id };
          return obj;
        });
        setDepartmentData(users);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getAllUsers = async () => {
    try {
      setUserLoading(true);
      const { data } = await MasterServices.getAllMUsersList();
      if (data.success) {
        let newUsers = data.data;
        let users = newUsers.map((el) => {
          let obj = { ...el, label: el.user_first_name, value: el.user_id };
          return obj;
        });
        setUserList(users);
      }
      setUserLoading(false);
    } catch (error) {
      setUserLoading(false);
    }
  };

  const handleDrawer = (type, id, obj) => {
    if (type === "edit" && id) {
      const initialValues = {
        user_id: obj.user_id,
        department_id: obj.department_id,
      };
      setFormData(initialValues);
      setId(id);
      setSelectedDept({
        label: obj.department.name,
        value: obj.department_id,
      });
      setSelectedUsers({
        label: obj.user.user_first_name,
        value: obj.user_id,
      });
    } else {
      setId("");
      setFormData(initialValues);
    }
    if (modalOpenFlage === false) {
      setmodalOpenFlage(true);
    }
  };

  const onDeleteOpen = (id) => {
    setConfirmationModal({ id, status: true });
  };

  const onDeleteUserDepartment = async (id) => {
    try {
      setIsLoading(true);
      const { data } = await Api().delete(
        `/api/dashboard/user-department/${id}`
      );
      if (data.status) {
        toast.success(data.data.message);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
    setConfirmationModal({ id: null, status: false });
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    enableReinitialize: true,
    initialValues: formData,
    validationSchema: userDepartmentSchema,
    onSubmit: async (values, action) => {
      if (id) {
        let payload = {
          id: id,
          user_id: values.user_id.value,
          department_id: values.department_id.value,
        };
        try {
          setIsLoading(true);
          const { data } = await Api().patch(
            "/api/dashboard/user-department",
            payload
          );
          if (data.status) {
            action.resetForm();
            if (modalOpenFlage === true) {
              getAll();
              setmodalOpenFlage(false);
            }
            toast.success(data.message);
          } else {
            toast.error(data.message);
          }
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
        }
      } else {
        let payload = {
          user_id: values.user_id.value,
          department_id: values.department_id.value,
        };
        try {
          setIsLoading(true);
          const { data } = await Api().post(
            "/api/dashboard/user-department",
            payload
          );
          if (data.status) {
            action.resetForm();
            if (modalOpenFlage === true) {
              getAll();
              setmodalOpenFlage(false);
            }
            toast.success(data.message);
          } else {
            toast.error(data.message);
          }
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
        }
      }
    },
  });

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <ConfirmationModal
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        // onDelete={() => onDeleteUserDepartment(confirmationModal.id)}
      />
      <Breadcrumb pages={pages} />
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">User Department</h1>
        <p className="mt-2 text-sm text-gray-700">
          A list of users and their assigned departments.
        </p>
      </div>
      <div className="mt-4 flex items-center justify-end gap-3">
        <CustomButton onClick={() => handleDrawer("add", "", {})}>
          Add User Department
        </CustomButton>
      </div>
      {isLoading ? (
        <FallingLinesLoader />
      ) : (
        <Table
          columns={user_department_columns({ onDeleteOpen, handleDrawer })}
          data={data && data.length > 0 ? data : []}
        />
      )}

      <div>
        <Transition.Root show={modalOpenFlage} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-30"
            onClose={setmodalOpenFlage}
          >
            <div className="fixed inset-0" />
            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="pointer-events-auto w-screen max-w-xs">
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          handleSubmit(e);
                        }}
                        className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
                      >
                        <div className="h-0 flex-1 overflow-y-auto">
                          <div className="bg-cyan-700 py-6 px-4 sm:px-6">
                            <div className="flex items-center justify-between">
                              <Dialog.Title className="text-lg font-medium text-white">
                                {id ? "Update" : "Add"} Department mapping
                              </Dialog.Title>
                              <div className="ml-3 flex h-7 items-center">
                                <button
                                  type="button"
                                  className="rounded-md bg-cyan-700 text-cyan-200 hover:text-white"
                                  onClick={() => setmodalOpenFlage(false)}
                                >
                                  <span className="sr-only">Close panel</span>
                                  <XMarkIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-1 flex-col justify-between">
                            <div className="p-4 sm:p-6">
                              <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                                <div>
                                  <label
                                    htmlFor="user_id"
                                    className="block text-sm font-medium text-gray-900"
                                  >
                                    User
                                  </label>
                                </div>
                                <div className="mt-1 sm:col-span-2 sm:mt-0">
                                  <Select
                                    name="user_id"
                                    id="user_id"
                                    value={selectedUsers}
                                    onChange={(selected) => {
                                      setFieldValue("user_id", selected);
                                      setSelectedUsers(selected);
                                    }}
                                    options={userList}
                                    className="basic-multi-select"
                                    classNamePrefix="Select user"
                                  />
                                  {errors.user_id && touched.user_id ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.user_id}
                                    </p>
                                  ) : null}
                                </div>
                              </div>

                              <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                                <div>
                                  <label
                                    htmlFor="department_id"
                                    className="block text-sm font-medium text-gray-900"
                                  >
                                    Department
                                  </label>
                                </div>
                                <div className="mt-1 sm:col-span-2 sm:mt-0">
                                  <Select
                                    name="department_id"
                                    id="department_id"
                                    value={selectedDept}
                                    onChange={(selected) => {
                                      setFieldValue("department_id", selected);
                                      setSelectedDept(selected);
                                    }}
                                    options={departmentData}
                                    className="basic-multi-select"
                                    classNamePrefix="Select department"
                                  />
                                  {errors.department_id &&
                                  touched.department_id ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.department_id}
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-shrink-0 justify-end px-4 py-4">
                          <button
                            type="button"
                            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                            onClick={() => {
                              setmodalOpenFlage(false);
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                          >
                            {id ? "Update" : "Add"}
                          </button>
                        </div>
                      </form>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    </div>
  );
};

export default UserDepartment;
